import React, {useEffect} from "react";
import {useLoaderData, useLocation} from "react-router-dom";

import { Header } from "@/app/components/header";
import { Accordion } from "@/components/ui/accordian";
import { MapEmbed } from "@/components/MapEmbed";
import { ContentWrapper } from "@/components/ContentWrapper";

import { getMinimalContacts } from "@/api/services/contact";
import { AnnotatedManufacturer } from "@/types/Manufacturer";
import { MinimalContact } from "@/types/Contact";
import { manufacturerHasSupplementalLinks } from "@/utils/checks";
import { parse_multiline_text } from "@/utils/text_formatting";

import { ContactsContent } from "./components/ContactsContent";
import { ContactEmailSection } from "./components/ContactEmailSection";
import { LinksSection } from "./components/LinksSection";
import { SectionTitle } from "./components/SectionTitle";
import { DetailHeader } from "./components/DetailHeader";
import { DetailBreadcrumb } from "./components/DetailBreadcrumb";
import { AggregatorContent } from "./components/AggregatorContent";
import { SearchBar } from "./components/SearchBar";
import { AssociatedOmniclasses } from "./components/AssociatedOmniclasses";
import { HomepageSection } from "./components/HomepageSection";
import { ProductImageSection } from "./components/ProductImageSection";
import {overlayLinksWithAggregatorContent} from "@/utils/transforms";
import {Footer} from "@/app/components/Footer";


export const ManufacturerDetails = () => {
  const location = useLocation();
  const manufacturer = useLoaderData() as AnnotatedManufacturer;

  const [contacts, setContacts] = React.useState<MinimalContact[] | null>(null);

  useEffect(() => {
    getMinimalContacts(manufacturer.title).then(setContacts);
  }, [manufacturer.title]);

  const omniclass = location.state ? (location.state as {omniclass: string}).omniclass : null;

  const hasSupplementalLinks = manufacturerHasSupplementalLinks(manufacturer);
  const aggregatorContent = overlayLinksWithAggregatorContent(manufacturer);

  return (
    <div className={"min-h-[100vh] flex flex-col justify-start"}>

      <Header />

      <SearchBar omniclass={omniclass || ""} />

      <ContentWrapper className={"p-8 max-w-full"}>

        <DetailBreadcrumb manufacturer={manufacturer} omniclass={omniclass} className={"mb-4"} />

        <DetailHeader manufacturer={manufacturer} />

        <div className={"flex flex-col gap-8"}>
          {/* TOP SECTION */}
          <div className={"flex gap-16 w-full"} id={"top-section"}>

            <div className={"flex flex-col gap-8 flex-grow"} id={"map-and-details"}>
              <div className={"flex gap-8 h-full"}>
                {/* Map Embed */}
                {manufacturer.formatted_address &&
                  <div className={"flex-grow"} id={"map-embed"}>
                    <MapEmbed manufacturer={manufacturer}/>
                  </div>
                }

                <div className={"flex flex-col gap-6 md:gap-4"} id={"detail-sections"}>
                  {/* TODO: header styling for these sections should be lightened dramatically */}
                  <HomepageSection manufacturer={manufacturer}/>
                  <ContactEmailSection manufacturer={manufacturer}/>
                  <AggregatorContent aggregatorContent={aggregatorContent}/>

                  {manufacturer.product_page_url && <div className={"flex flex-col"}>
                      <SectionTitle className={"text-lg"}>Products</SectionTitle>
                      <a href={manufacturer.product_page_url} className={"text-sky-600 hover:underline"} target={"_blank"} rel={"noreferrer"}>{manufacturer.product_page_url}</a>
                  </div>}

                  {/* TODO: show address here */}
                </div>
              </div>

              <ContactsContent contacts={contacts}/>
            </div>

            {hasSupplementalLinks && <div className={"flex flex-col gap-2"} id={"supplemental-links"}>
              <LinksSection manufacturer={manufacturer}/>
            </div>}

          </div>{/* END TOP SECTION */}

          {/* Product Images Section */}
          <ProductImageSection 
            manufacturerTitle={manufacturer.title}
            omniclass={omniclass}
          />

          <Accordion type="multiple">
            {/* TODO: this accordion should be open by default */}
            <AssociatedOmniclasses manufacturer={manufacturer} />
          </Accordion>

          {manufacturer.description && <div>
            <SectionTitle>Description:</SectionTitle>
            {manufacturer.description && parse_multiline_text(manufacturer.description).map((line, index) => (
              <p key={index} className={`text-gray-500 leading-7 ${index !== 0 ? 'pt-2' : ''}`}>{line}</p>
            ))}
          </div>
          }
        </div>
      </ContentWrapper>

      <Footer />
    </div>
  )
}