import { isDevelopment } from '@/utils/config';

const devEndpoint = 'localhost:8000';
// noinspection SpellCheckingInspection
const prodEndpoint = 'bim-workshop-backend-hzy7i.ondigitalocean.app';

const endpoint = isDevelopment ? devEndpoint : prodEndpoint;

const apiEndpoint = `${endpoint}/api`;
const adminEndpoint = `${endpoint}/admin`;

export const apiEndpoints = {
  // get omniclass table data
  get_omniclass_table: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/omniclass/get`,

  // used by `SearchInput` to get autocomplete options
  all_omniclasses: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/omniclass/get/all`,

  // used by `ResultsContainer` to get the list of manufacturers for the given omniclass
  all_manufacturers: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/omniclass/manufacturers/all`,

  // used by `ResultsContainer` to POST selected parameter-values to get an ordered list of manufacturers
  manufacturers_by_selected_parameters: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/manufacturer/selected_parameters`,

  get_multiple_manufacturers: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/manufacturer/get/multiple`,

  send_rfi_request: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/omniclass/manufacturers/request`,

  get_contacts: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/manufacturer/contacts/get/`,  // add the manufacturer title as the last path parameter

  generate_rfi_message: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/omniclass/manufacturers/request/generate`,

  manufacturer_logos_with_variants: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/manufacturer/logos/variant/all`,
  manufacturer_logos_for_omniclass: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/manufacturer/overview/omniclass`,   // omniclass title is given as a path parameter

  get_manufacturer_data: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/manufacturer/data`,  // when retrieving, manufacturer title is added as a query parameter

  all_associations: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/omniclass/associations/all`,

  // Product image endpoints
  get_manufacturer_images: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/manufacturer/:manufacturerTitle/product-images`,  
  get_manufacturer_omniclass_images: `${isDevelopment ? 'http' : 'https'}://${apiEndpoint}/manufacturer/:manufacturerTitle/omniclass/:omniclassName/product-images`,  
};

export const adminEndpoints = {
  // used by `Status` component to get the status of the backend
  status: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/metrics`,


  // omniclass specific endpoints
  omniclass: {
    all: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/all`,
    all_general: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/all/general`, // used by `AdminGeneralOmniclassSearch` to get all general omniclasses
    unreviewed: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/not_reviewed`,
    delete: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/delete/`,   // add the omniclass name as the last path parameter
    get: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/get`,         // omniclass name is added as a query parameter `name`
    upload: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/upload`,
    missing_manufacturers: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/missing_manufacturers`,
    update: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/update`,
    create: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/create`,

    // used by `OmniclassEdit` to update the manufacturers associated with an omniclass. Use `apiEndpoints.all_manufacturers` to get the list of manufacturers.
    update_manufacturers: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/manufacturers`,
  },

  omniclass_variant: {
    get: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/variant/get`,
    create: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/variant/create`,
    update: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/variant/update`,
    get_owned: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/omniclass/variant/owned`,
  },

  // manufacturer specific endpoints
  manufacturer: {
    upload: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/upload`,
    all: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/all`,
    data: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/data`,  // when retrieving, manufacturer title is added as a query parameter
    create: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/create`,
    delete: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/delete`,
    search: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/search`,

    // used by `ManufacturerEditor` to get and update the associated omniclasses
    associated_omniclasses: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/omniclasses`,  // when retrieving, manufacturer title is added as a query parameter

    // Product image admin endpoints
    get_admin_manufacturer_images: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/:manufacturerTitle/product-images`,  
    update_image_omniclass: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/product-images/update`,
    upload_manufacturer_image: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/:manufacturerTitle/product-images`,  

    utilities: {
      missing_logos: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/utilities/missing_logos`,
      upload_logos: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/manufacturer/utilities/upload_logo`,  // add the manufacturer title as the last path parameter
    }
  },

  // contact specific endpoints
  contact: {
    all: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/contacts/all/`, // add the manufacturer title as the last path parameter
    get: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/contacts/get/`,  // add the contact id as the last path parameter
    update: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/contacts/update/`,  // add the contact id as the last path parameter
    delete: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/contacts/delete/`,  // add the contact id as the last path parameter
    create: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/contacts/create/`,  // add the manufacturer title as the last path parameter
  },

  users: {
    // used to generate invite links
    invite: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/users/invite`,

    // used for showing the list of invited users and their status
    invited_users: `${isDevelopment ? 'http' : 'https'}://${adminEndpoint}/users/invited`,
  }
}