import React, {useEffect, useState, useRef, ReactNode} from 'react';
import { ProductImage, getManufacturerProductImages, getManufacturerOmniclassProductImages } from '@/api/services/imageService';
import { get_product_image_url } from '@/utils/logos';
import { SectionTitle } from './SectionTitle';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/Dialog";
import { Button } from "@/components/ui/Button";
import { ChevronDown, ChevronUp } from "lucide-react";

interface ProductCardProps {
  product: ProductImage;
}

const ProductCard = ({ product }: ProductCardProps) => {
  const label = product.product_name || product.omniclass_name || `By ${product.manufacturer_title}`;
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={`mb-4 break-inside-avoid ${!imageLoaded ? 'opacity-0' : 'opacity-100 transition-opacity duration-300'}`}>
      <div className="overflow-hidden rounded-lg border border-gray-200 hover:border-gray-300">
        <Dialog>
          <DialogTrigger asChild>
            <img
              ref={imageRef}
              src={get_product_image_url(product)}
              alt={label}
              className="w-full object-cover hover:cursor-"
              onLoad={handleImageLoad}
            />
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{label}</DialogTitle>
            </DialogHeader>
            <div className="flex flex-col gap-8">
              <img
                src={get_product_image_url(product)}
                alt={label}
                className="w-full h-full object-cover"
              />
              {product.product_description && (
                <div>
                  <h3 className="text-lg font-semibold text-slate-500 mb-4">Description:</h3>
                  <p>{product.product_description}</p>
                </div>
              )}
              {product.product_url && (
                <a
                  href={product.product_url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Product
                </a>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

interface MasonryGridProps {
  children: ReactNode;
  expanded: boolean;
}

/**
 * Expanding masonry grid component.
 */
const MasonryGrid = ({ children, expanded }: MasonryGridProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [children]); // Update when children change

  return (
    <div className="relative">
      <div
        style={{
          maxHeight: expanded ? `${contentHeight}px` : '384px',
          transition: 'max-height 500ms ease-in-out'
        }}
        className="overflow-hidden"
      >
        <div
          ref={contentRef}
          className="columns-1 sm:columns-2 md:columns-3 lg:columns-4 gap-4"
        >
          {children}
        </div>
      </div>
      <div
        className={`
          absolute bottom-0 left-0 right-0 h-24 
          bg-gradient-to-t from-white to-transparent 
          pointer-events-none
          transition-opacity duration-300
          ${expanded ? 'opacity-0' : 'opacity-100'}
        `}
      />
    </div>
  );
};

interface ProductImageSectionProps {
  manufacturerTitle: string;
  omniclass?: string | null;
}

export const ProductImageSection = ({
  manufacturerTitle,
  omniclass
}: ProductImageSectionProps) => {
  const [images, setImages] = useState<ProductImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        setError(null);

        const fetchedImages = omniclass
          ? await getManufacturerOmniclassProductImages(manufacturerTitle, omniclass)
          : await getManufacturerProductImages(manufacturerTitle);

        setImages(fetchedImages);
      } catch (err) {
        setError('Failed to load product images');
        console.error('Error fetching product images:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [manufacturerTitle, omniclass]);

  if (loading) {
    return (
      <div className="w-full h-32 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
      </div>
    );
  }

  if (images.length === 0 || error) {
    return null;
  }

  return (
    <div className="w-full">
      <SectionTitle>Product Images</SectionTitle>
      <div className="relative">
        <MasonryGrid expanded={expanded}>
          {images.map((image, index) => (
            <ProductCard
              key={index}
              product={image}
            />
          ))}
        </MasonryGrid>

        <div className="flex justify-center mt-4">
          <Button
            variant="ghost"
            onClick={() => setExpanded(!expanded)}
            className="flex items-center gap-2 text-lg"
          >
            {expanded ? (
              <>
                Show Less <ChevronUp className="h-4 w-4" />
              </>
            ) : (
              <>
                View All <ChevronDown className="h-4 w-4" />
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductImageSection;